import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Products.css";
import ProductItem from "../../components/v2/ProductItemProducts";
import Navbar from "../../components/v2/homepage/Navbar";
import OverlayComponent from "../../components/v2/homepage/FloatingMenu";
import FiltersComponent from "../../components/v2/Filters";
import ProductsView from "../../img/products-view.svg";
import ProductsViewSecond from "../../img/products-view-second.svg";
import Filters from "../../img/filters.svg";
import FiltersClose from "../../img/filters-close.svg";
import DesktopSearch from "../../components/desktop/SearchBox.jsx";
import { Html5Qrcode } from "html5-qrcode";
import product_type from "./jsons/type.json";
import SearchCategory from "../../components/desktop/SearchCategory.jsx";
import SearchBox from "../../components/desktop/SearchBox.jsx";
import NavbarDesktop from "../../components/desktop/NavbarDesktop";
import FooterDesktop from "../../components/desktop/FooterDesktop";


const categories = [
  {
    title: "100% RECYCLED",
    img: "./images/recycled_background.png",
    code: "recycled",
  },
  {
    title: "MICROPLASTICS FREE",
    img: "./images/microplastics_background.png",
    code: "microplastics",
  },
  {
    title: "100% COTTON",
    img: "./images/cotton_background.svg",
    code: "cotton",
  },
  {
    title: "BIO BASED",
    img: "./images/biobased_background.png",
    code: "biobased",
  },
];

const Search = ({ productList }) => {
  const [viewInLine, setViewInLine] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [productId, setProductId] = useState("");
  const [showQrReader, setShowQrReader] = useState(false);
  const [qrResult, setQrResult] = useState("");
  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);


  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenWidth);
    return () => window.removeEventListener("resize", updateScreenWidth);
  }, []);

  // Para o componente Filters
  const [brands, setBrands] = useState([]);
  const [descriptions, setDescriptions] = useState([]);
  const [ScoreRange, setScoreRange] = useState([0, 100]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [ClickOutside, setClickOutside] = useState([]);

  useEffect(() => {
    let html5Qrcode;

    if (showQrReader) {
      html5Qrcode = new Html5Qrcode("qr-reader");

      const config = { facingMode: "environment" };
      html5Qrcode
        .start(
          config,
          {
            fps: 10,
            qrbox: 250,
          },
          (decodedText) => {
            handleQrScan(decodedText);
          }
        )
        .catch((err) => {
          handleQrError(err);
        });

      return () => {
        if (html5Qrcode) {
          html5Qrcode
            .stop()
            .then(() => {
              html5Qrcode.clear();
            })
            .catch((err) => {
              console.error("Failed to stop QR code reader: ", err);
            });
        }
      };
    }
  }, [showQrReader]);

  const handleInputChange = (event) => {
    const newProductId = event.target.value;
    setProductId(newProductId);
  };

  const handleOpenMenu = () => {
    setMenuOpen(true);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleQrScan = (result) => {
    try {
      const url = new URL(result);
      const productIdFromUrl = url.searchParams.get("productId");
      if (productIdFromUrl) {
        setProductId(productIdFromUrl);
        setShowQrReader(false); // Hide QR reader after scanning
        navigate(`/products?productId=${productIdFromUrl}`);
      }
    } catch (error) {
      console.error("Error parsing QR code result: ", error);
    }
  };

  const handleQrError = (err) => {
    console.error("QR code scanning error: ", err);
  };

  const handleClickFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleCloseMenuApplyFilters = (menuclose) => {
    setShowFilters(!showFilters);
  };

  const filteredProducts = hasFilters
    ? productList.filter((product) => {
        const brandCondition =
          selectedBrands.length === 0 || selectedBrands.includes(product.brand);
        const scoreCondition =
          product.score >= ScoreRange[0] && product.score <= ScoreRange[1];
        const typeCondition =
          selectedTypes.length === 0 || selectedTypes.includes(product.type);
        const searchBoxCondition = product.id
          .toString()
          .toLowerCase()
          .includes(productId.toLowerCase());
        return (
          scoreCondition &&
          brandCondition &&
          typeCondition &&
          searchBoxCondition
        );
      })
    : productList.filter((product) => {
        // Segunda condição: verifica se o productId é uma string vazia ou se está contido no ID do produto

        return (
          productId === "" ||
          product.id.toString().toLowerCase().includes(productId.toLowerCase())
        );
      });

  // Sorteia por score
  /* filteredProducts.sort((a, b) => b.score - a.score); */

  /*
  const filteredProducts = productList.filter(product =>
    productId === "" || product.id.toString().toLowerCase().includes(productId.toLowerCase())
  ); */

  //Obtem os seleccionados da Filters
  const handleGetFilters = (selectedTypes, convertedRange, selectedBrands) => {
    //console.log("Filters selected in parent:", { selectedTypes, convertedRange, selectedBrands });
    setHasFilters(true);
    setScoreRange(convertedRange);
    setSelectedBrands(selectedBrands);
    setSelectedTypes(selectedTypes);

    //console.log("score range" + ScoreRange)
    //console.log("hasfilters" + hasFilters)
  };

  const handleClickOutside = (ClickOutside) => {
    setShowFilters(ClickOutside);
  }

  //console.log(productList);

  useEffect(() => {
    const uniqueBrands = [
      ...new Set(productList.map((product) => product.brand)),
    ];
    setBrands(uniqueBrands);

    const allAssociations = [];
    product_type.forEach((category) => {
      // allAssociations.push({ code: category.code, description: category.description.en });
      category.options.forEach((option) => {
        allAssociations.push({
          code: option.code,
          description: option.description.en,
        });
      });
    });
    console.log(brands);
    setDescriptions(allAssociations);
    //console.log("Product List" + productList)
  }, [productList, product_type]);

  return (
    <div className="page-products-container-search">
      {screenWidth <= 768 && (
        <div className="top-info">
          <div className="title">Search</div>

          <div className="searchbar-v2">
            <DesktopSearch
              productId={productId}
              handleInputChange={handleInputChange}
              setShowQrReader={setShowQrReader}
            ></DesktopSearch>
          </div>
          <div className="filters-and-group-by">
            <div className="filters" onClick={handleClickFilters}>
              Filters
              {showFilters ? (
                <img className="filters-img-close" src={FiltersClose} alt="" />
              ) : (
                <img src={Filters} alt="" />
              )}
            </div>

            <div className="group-by">
              <div
                className={`icon ${!viewInLine ? "active" : ""}`}
                onClick={() => setViewInLine(false)}
              >
                <img src={ProductsView} alt="view1" />
              </div>
              <div
                className={`icon ${viewInLine ? "active" : ""}`}
                onClick={() => setViewInLine(true)}
              >
                <img src={ProductsViewSecond} alt="view2" />
              </div>
            </div>
          </div>
          {showFilters && (
            <FiltersComponent
              brands={brands}
              descriptions={descriptions}
              onFiltersChange={handleGetFilters}
              onApplyFilters={handleCloseMenuApplyFilters}
              initialSelectedTypes={selectedTypes}
              initialScoreRange={ScoreRange}
              initialSelectedBrands={selectedBrands}
              onClickOutside={handleClickOutside}
            />
          )}
        </div>
      )}

      {showQrReader && screenWidth <= 768 && (
        <div className="qr-reader">
          <div id="qr-reader" style={{ width: "100%", height: "auto" }}></div>
        </div>
      )}
      {/* para mobile aparecer searchcategory ou map produtos */}
      {!showQrReader && screenWidth <= 768 && (
        <div
          className={
            !viewInLine ? "page-products-content" : "page-products-content-view"
          }
        >
          {(productId || hasFilters) && (
            <div
              className={
                viewInLine ? "page-product-items-inline" : "page-product-items"
              }
            >
              {filteredProducts.map((product) => (
                <div
                  className={
                    !viewInLine
                      ? "page-product-item"
                      : "page-product-item-inline"
                  }
                  key={product.id}
                >
                  <ProductItem productObject={product} />
                </div>
              ))}
            </div>
          )}
          {!productId && !hasFilters && (
            <SearchCategory categories={categories} />
          )}
        </div>
      )}

      {screenWidth > 768 && (
        <div className="page-products-navbar">
          {" "}
          <NavbarDesktop />
        </div>
      )}
      {screenWidth > 768 && (
        <div className="hud-search-desktop">
          <div className="top-info-desktop">
            <div className="searchbar-v2">
              <DesktopSearch
                productId={productId}
                handleInputChange={handleInputChange}
                setShowQrReader={setShowQrReader}
              />
            </div>
            <div className="filters-and-group-by-desktop-search">
              <div className="filters" onClick={handleClickFilters}>
                Filters
                {showFilters ? (
                  <img
                    className="filters-img-close"
                    src={FiltersClose}
                    alt=""
                  />
                ) : (
                  <img src={Filters} alt="" />
                )}
              </div>
            </div>
            {showFilters && (
              <FiltersComponent
                brands={brands}
                descriptions={descriptions}
                onFiltersChange={handleGetFilters}
                onApplyFilters={handleCloseMenuApplyFilters}
                initialSelectedTypes={selectedTypes}
                initialScoreRange={ScoreRange}
                initialSelectedBrands={selectedBrands}
                onClickOutside={handleClickOutside}
              />
            )}
          </div>
          <div className="search-suggestions-title">Suggestions</div>
        </div>
      )}
      {/* para desktop aparecer searchcategory ou map produtos */}
      {screenWidth > 768 && !productId && !hasFilters && (
        <SearchCategory categories={categories} />
      )}
      {screenWidth > 768 && (productId || hasFilters) && (
        <div
          className={
            viewInLine ? "page-product-items-inline" : "page-product-items-desktop search"
          }
        >
          {filteredProducts.map((product) => (
            <div
              className={
                !viewInLine ? "page-product-item" : "page-product-item-inline"
              }
              key={product.id}
            >
              <ProductItem productObject={product} />
            </div>
          ))}
        </div>
      )}

      {screenWidth > 768 && (
        <div className="navbar-footer-products-search">
          <FooterDesktop />
        </div>
      )}

      <div className="navbar-footer">
        <Navbar MenuClick={handleOpenMenu}></Navbar>
        <OverlayComponent isOpen={isMenuOpen} onClose={handleCloseMenu} />
      </div>
    </div>
  );
};

export default Search;
