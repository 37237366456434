import React, { useState, useEffect, useRef } from "react";
import "./ProductGallery.css";
import Certifications from "../Certifications";
/* import ProductHeaderV2 from "../ProductHeaderV2"; */

const ProductGallery = ({
  productImgs,
  recycled,
  compositionList,
  certifications,
  touchAreaRef,
}) => {
  const [mainImg, setMainImg] = useState(productImgs[0]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [currentImgIndex, setCurrentImgIndex] = useState(0);
  const galleryRef = useRef(null);

  const handleThumbnailClick = (img, index) => {
    setMainImg(img);
    setCurrentImgIndex(index);
  };

  const handleScrollBarClick = (index) => {
    setMainImg(productImgs[index]);
    setCurrentImgIndex(index);
  };

  useEffect(() => {
    // Update the main image when productImgs changes
    if (productImgs.length > 0) {
      setMainImg(productImgs[0]);
      setCurrentImgIndex(0);
    }
  }, [productImgs]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    const handleWheel = (event) => {
      const newIndex =
        (currentImgIndex + (event.deltaY > 0 ? 1 : -1) + productImgs.length) %
        productImgs.length;
      setMainImg(productImgs[newIndex]);
      setCurrentImgIndex(newIndex);
    };

    window.addEventListener("resize", handleResize);

    const currentGallery = galleryRef.current;
    if (currentGallery) {
      currentGallery.addEventListener("wheel", handleWheel);
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      if (currentGallery) {
        currentGallery.removeEventListener("wheel", handleWheel);
      }
    };
  }, [currentImgIndex, productImgs]);

  const thumbnails = productImgs;

  return (
    <div className="productgallery-container" ref={galleryRef}>
      {screenWidth <= 768 && (
        <Certifications
          touchAreaRef={touchAreaRef}
          recycled={recycled}
          compositionList={compositionList}
          certifications={certifications}
        />
      )}
      {screenWidth > 768 && productImgs.length >= 1 && (
        <div className="img-thumbnails">
          {thumbnails.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`thumbnail-${index}`}
              className="img-thumbnail"
              onClick={() => handleThumbnailClick(img, index)}
            />
          ))}
        </div>
      )}
      {screenWidth > 768 && productImgs.length >= 1 && (
        <div className="scroll-bar">
          {productImgs.map((_, index) => (
            <div
              key={index}
              className={`scroll-bar-item ${
                index === currentImgIndex ? "active" : ""
              }`}
              onClick={() => handleScrollBarClick(index)}
            />
          ))}
        </div>
      )}

      <img src={mainImg} alt="img-main-produto" className="img-main-produto" />
    </div>
  );
};

export default ProductGallery;
