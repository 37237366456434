import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "./NavbarDesktop.css";
import searchicondesktop from "../../img/search_desktop.svg";
import SearchSelected from "../../img/SearchSelected.svg";
import dpp from "../../img/dpp_logo_desktop.svg";
import LanguageSelection from "./LanguageSelection";

const NavbarDesktop = () => {
  // Obtém a rota atual
  const currentLocation = useLocation();

  // Verifica a rota atual
  const isProductsRoute = currentLocation.pathname === "/view";
  const isAboutRoute = currentLocation.pathname === "/about";
  const isSearchRoute = currentLocation.pathname === "/search";
  const languages = ["EN", "PT", "DE", "ES", "FR"];
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredAbout, setIsHoveredAbout] = useState(false);

  return (
    <div className="navbar-desktop">
      <div className="navbar-left-desktop">
        <NavLink to="/" className="navbar-item-desktop">
          <span
            className="text-navbardesktop"
            onMouseEnter={() => setIsHoveredAbout(true)}
            onMouseLeave={() => setIsHoveredAbout(false)}
          >
            About Us
          </span>
          {(isAboutRoute || isHoveredAbout) && (
            <div className="text-underline-navbardesktop"> </div>
          )}
        </NavLink>
        <div className="separator-navbar-line"></div>
        <NavLink to="/view" className="navbar-item-desktop">
          <span
            className="text-navbardesktop"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Products{" "}
          </span>
          {(isProductsRoute || isHovered) && (
            <div className="text-underline-navbardesktop"></div>
          )}
        </NavLink>
      </div>
      <div className="navbar-center-desktop">
        <a href="/" className="navbar-item-center-desktop">
          <img src={dpp} alt="Ícone" className="main-icon-navbar-desktop"></img>
        </a>
      </div>
      <div className="navbar-right-desktop">
        <NavLink
          to="/search"
          className={`navbar-item-desktop-search ${
            isSearchRoute ? "active" : ""
          }`}
        >
          <div
            className={`icon-search-dot-selected ${
              isSearchRoute ? "active" : ""
            }`}
          >
            <img
              className={`icon-search-desktop ${isSearchRoute ? "active" : ""}`}
              src={searchicondesktop}
              alt="Ícone"
            ></img>
            {/*   <div className="dot-desktop-search"> . </div> */}
          </div>
        </NavLink>
        <LanguageSelection languages={languages}></LanguageSelection>
      </div>
    </div>
  );
};

export default NavbarDesktop;
