import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useRef,
} from "react";
import "./scoreFilter.css";

const ScoreFilter = forwardRef(({ onRangeChange }, ref) => {
  const [selectedRange, setSelectedRange] = useState([0, 4]); // Inicialmente definido como um array válido
  const [doubleClickedSegment, setDoubleClickedSegment] = useState(null);
  const labels = ["A", "B", "C", "D", "E", "F"];
  const [convertedRange, setConvertedRange] = useState([0, 100]);
  const [draggingLeft, setDraggingLeft] = useState(false);
  const [draggingRight, setDraggingRight] = useState(false);
  const scoreBarRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Função para garantir que selectedRange é um array com dois números
  const validateRange = (range) => {
    if (!Array.isArray(range) || range.length !== 2) {
      return [0, 4]; // Valor padrão se range não for válido
    }

    const [start, end] = range;
    if (typeof start !== "number" || typeof end !== "number") {
      return [0, 4]; // Valor padrão se start ou end não forem números
    }

    return [
      Math.max(0, Math.min(start, end)),
      Math.min(4, Math.max(start, end)),
    ];
  };

  useImperativeHandle(ref, () => ({
    reset() {
      setSelectedRange([0, 4]);
      setDoubleClickedSegment(null);
    },
    setRange(range) {
      const validatedRange = validateRange(range);
      const convertedFiltersRange = convertRangeToScale(range);
      /* console.log("converted filters range" + convertedFiltersRange); */
      setSelectedRange(convertedFiltersRange);
    },
  }));

  const handleTouchStart = (e) => {
    const touchX = e.touches[0].clientX;
    const segmentRect = scoreBarRef.current.getBoundingClientRect();
    const segmentWidth = segmentRect.width / labels.length;
    const touchPosition = touchX - segmentRect.left;
    const clampedTouchPosition = Math.max(
      0,
      Math.min(touchPosition, segmentRect.width)
    );
    const index = Math.floor(clampedTouchPosition / segmentWidth);

    const [start, end] = validateRange(selectedRange);
    if (index <= start) {
      setDraggingLeft(true);
    } else if (index >= end) {
      setDraggingRight(true);
    } else {
      setDraggingLeft(false);
      setDraggingRight(false);
    }
  };

  const handleTouchMove = (e) => {
    if (draggingLeft || draggingRight) {
      const touchX = e.touches[0].clientX;
      const segmentRect = scoreBarRef.current.getBoundingClientRect();
      const segmentWidth = segmentRect.width / labels.length;
      const touchPosition = touchX - segmentRect.left;
      const index = Math.floor(touchPosition / segmentWidth);

      setSelectedRange((prevRange) => {
        const validatedRange = validateRange(prevRange);
        const [start, end] = validatedRange;

        if (draggingLeft) {
          return [Math.max(0, Math.min(index, end)), end];
        } else if (draggingRight) {
          return [start, Math.min(labels.length - 2, Math.max(index, start))];
        }

        return validatedRange;
      });
    }
  };

  const handleTouchEnd = () => {
    setDraggingLeft(false);
    setDraggingRight(false);
  };

  const handleMouseDown = (e) => {
    const { clientX } = e;
    const segmentRect = scoreBarRef.current.getBoundingClientRect();
    const segmentWidth = segmentRect.width / labels.length;
    const touchPosition = clientX - segmentRect.left;
    const clampedTouchPosition = Math.max(
      0,
      Math.min(touchPosition, segmentRect.width)
    );
    const index = Math.floor(clampedTouchPosition / segmentWidth);

    const [start, end] = validateRange(selectedRange);
    if (index <= start) {
      setDraggingLeft(true);
    } else if (index >= end) {
      setDraggingRight(true);
    } else {
      setDraggingLeft(false);
      setDraggingRight(false);
    }
  };

  const handleMouseMove = (e) => {
    if (draggingLeft || draggingRight) {
      const { clientX } = e;
      const segmentRect = scoreBarRef.current.getBoundingClientRect();
      const segmentWidth = segmentRect.width / labels.length;
      const touchPosition = clientX - segmentRect.left;
      const clampedTouchPosition = Math.max(
        0,
        Math.min(touchPosition, segmentRect.width)
      );
      const index = Math.floor(clampedTouchPosition / segmentWidth);

      setSelectedRange((prevRange) => {
        const validatedRange = validateRange(prevRange);
        const [start, end] = validatedRange;

        if (draggingLeft) {
          return [Math.max(0, Math.min(index, end)), end];
        } else if (draggingRight) {
          /*  return [start, Math.max(index, start)]; */
          return [start, Math.min(labels.length - 2, Math.max(index, start))];
        }

        return validatedRange;
      });
    }
  };

  const handleMouseUp = () => {
    setDraggingLeft(false);
    setDraggingRight(false);
  };

  const handleClick = (index) => {
    if (doubleClickedSegment === index) {
      setSelectedRange([index, index]);
      setDoubleClickedSegment(null);
    } else {
      setSelectedRange((prevRange) => {
        const validatedRange = validateRange(prevRange);
        const [start, end] = validatedRange;

        if (Math.abs(index - start) <= Math.abs(end - index)) {
          return [Math.min(index, end), Math.max(index, end)];
        } else {
          return [Math.min(index, start), Math.max(index, start)];
        }
      });
      setDoubleClickedSegment(index);
    }
  };

  const isSegmentSelected = (index) => {
    const [start, end] = validateRange(selectedRange);
    return index >= Math.min(start, end) && index <= Math.max(start, end);
  };

  const isLastSelectedSegment = (index) => {
    const [start, end] = validateRange(selectedRange);
    return index === end && isSegmentSelected(index);
  };

  const isFirstSelectedSegment = (index) => {
    const [start] = validateRange(selectedRange);
    return index === start && isSegmentSelected(index);
  };

  const isVisibleLabel = (index) => {
    const [start, end] = selectedRange;
    return index === start || index === end + 1;
  };

  const convertScaleToRange = (selectedRange) => {
    const gradeMap = {
      0: [75, 100], // A até B
      1: [65, 84.99], // B até C
      2: [55, 74.99], // C até D
      3: [45, 64.99], // D até E
      4: [0, 54.99], // E até F
    };

    if (!Array.isArray(selectedRange) || selectedRange.length !== 2) {
      return "Invalid range";
    }

    const [start, end] = selectedRange;
    if (start < 0 || start > 4 || end < 0 || end > 4) {
      return "Invalid range";
    }

    const startRange = gradeMap[start];
    const endRange = gradeMap[end];
    const combinedRange = [
      Math.min(startRange[0], endRange[0]),
      Math.max(startRange[1], endRange[1]),
    ];

    return combinedRange;
  };

  const getLetterGrade = (score) => {
    if (score < 0 || score > 100) {
      return "Null";
    } else if (score >= 85.0) {
      return "A";
    } else if (score >= 75.0) {
      return "B";
    } else if (score >= 65.0) {
      return "C";
    } else if (score >= 55.0) {
      return "D";
    } else if (score >= 45.0) {
      return "E";
    } else if (score < 45.0) {
      return "F";
    } else {
      return "Null";
    }
  };

  const gradeScaleMap = {
    "A,B": [0, 0],
    "A,C": [0, 1],
    "A,D": [0, 2],
    "A,E": [0, 3],
    "A,F": [0, 4],
    "B,C": [1, 1],
    "B,D": [1, 2],
    "B,E": [1, 3],
    "B,F": [1, 4],
    "C,D": [2, 2],
    "C,E": [2, 3],
    "C,F": [2, 4],
    "D,E": [3, 3],
    "D,F": [3, 4],
    "E,F": [4, 4],
    "A,A": [0, 0],
    "B,B": [1, 1],
    "C,C": [2, 2],
    "D,D": [3, 3],
    "E,E": [4, 4],
    "F,F": [4, 4],
  };

  const getScaleFromLetters = (letters) => {
    const key = letters.join(",");
    return gradeScaleMap[key] || "Invalid range";
  };

  const convertRangeToScale = (interval) => {
    /*     const gradeMap = [
      { range: [75, 100], scale: 0 }, // A até B
      { range: [65, 74.99], scale: 1 }, // B até C
      { range: [55, 64.99], scale: 2 }, // C até D
      { range: [45, 54.99], scale: 3 }, // D até E
      { range: [0, 44.99], scale: 4 }, // E até F
    ];
 */
    const firstLetterScore = interval[1];
    const lastLetterScore = interval[0];

    const firstLetter = getLetterGrade(firstLetterScore);
    const lastLetter = getLetterGrade(lastLetterScore);

    const intervalLetters = [firstLetter, lastLetter];

    /* console.log(intervalLetters); */

    const scaleLetters = getScaleFromLetters(intervalLetters);

    /*  console.log("interval letters" + scaleLetters); */

    /*     let minScale = 4;
    let maxScale = 0; */

    /*     for (let i = 0; i < gradeMap.length; i++) {
      const { range, scale } = gradeMap[i];
      const [rangeMin, rangeMax] = range;

      if (interval[1] >= rangeMin && interval[0] <= rangeMax) {
        minScale = Math.min(minScale, scale);
        maxScale = Math.max(maxScale, scale);
      }
    } */

    return scaleLetters;
  };

  useEffect(() => {
    const newConvertedRange = convertScaleToRange(validateRange(selectedRange));
    setConvertedRange(newConvertedRange);
  }, [selectedRange]);

  useEffect(() => {
    onRangeChange(convertedRange);
  }, [convertedRange, onRangeChange]);

  useEffect(() => {
    const scoreBar = scoreBarRef.current;
    if (scoreBar) {
      if (isMobile) {
        scoreBar.addEventListener("touchstart", handleTouchStart);
        scoreBar.addEventListener("touchmove", handleTouchMove);
        scoreBar.addEventListener("touchend", handleTouchEnd);
      } else {
        scoreBar.addEventListener("mousedown", handleMouseDown);
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
      }
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (scoreBar) {
        if (isMobile) {
          scoreBar.removeEventListener("touchstart", handleTouchStart);
          scoreBar.removeEventListener("touchmove", handleTouchMove);
          scoreBar.removeEventListener("touchend", handleTouchEnd);
        } else {
          scoreBar.removeEventListener("mousedown", handleMouseDown);
        }
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, [isMobile, draggingLeft, draggingRight]);

  return (
    <div className="score-scale-filters">
      <div
        className={
          isMobile ? "score-labels-filters" : "score-labels-filters-desktop"
        }
      >
        {labels.map((label, index) => (
          <span
            key={index}
            className={`label ${isVisibleLabel(index) ? "" : "faded"}`}
          >
            {label}
          </span>
        ))}
      </div>
      <div
        className="score-bar-filters"
        ref={scoreBarRef}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onMouseDown={handleMouseDown}
      >
        {labels.slice(0, 5).map((label, index) => (
          <div
            key={index}
            className={`score-segment-filters ${
              isSegmentSelected(index) ? "selected" : ""
            } ${isLastSelectedSegment(index) ? "circle-right" : ""} ${
              isFirstSelectedSegment(index) ? "circle-left" : ""
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
});

export default ScoreFilter;
