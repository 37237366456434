import React, { useState } from "react";
import "./LanguageSelection.css";
import UpArrow from "../../img/language-selection.svg";
import FiltersClose from "../../img/filters-close.svg";

// Recebe lista de linguagens em array

const LanguageSelection = ({ languages, isHomepage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("EN");

  const toggleLanguageSelection = () => {
    setIsOpen(!isOpen);
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    setIsOpen(false);
  };

  return (
    <div className={`language-selection ${isOpen ? "open" : ""}`}>
      <div
        /* onClick={toggleLanguageSelection} */
        className="language-selection-button"
      >
        <div className="language-selection-selected-container">
          <div
            className={`language-selection-selected ${
              isHomepage ? "homepage" : ""
            } `}
          >
            {selectedLanguage}
          </div>
          {isOpen ? (
            <div className={`img-close ${isHomepage ? "homepage" : ""} `}>
              <img src={UpArrow} alt="" />
            </div>
          ) : (
            <div
              className={`img-close closed ${isHomepage ? "homepage" : ""} `}
            >
              <img src={UpArrow} alt="" />
            </div>
          )}
        </div>
      </div>

      {isOpen && (
        <div class="language-selection-menu-container">
          <ul
            className={`language-selection-menu ${
              isHomepage ? "homepage" : ""
            }`}
          >
            {languages
              .filter((language) => language !== selectedLanguage)
              .map((language, index, array) => (
                <React.Fragment key={language}>
                  <li
                    key={language}
                   /*  onClick={() => handleLanguageSelect(language)} */
                  >
                    {language}
                  </li>
                  {index < array.length - 1 && (
                    <div className="separator-languages">
                      <div className="separator-languages-line"> </div>
                    </div>
                  )}
                </React.Fragment>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageSelection;